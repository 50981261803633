import React, { Dispatch, SetStateAction } from "react";

import Filter from "./Filter";
import Sorter from "./Sorter";

interface IHeaderProps {
  companies: String[];
  selectedCompanies: String[];
  setSelectedCompanies: Dispatch<SetStateAction<never[]>>;
  sortOption: String;
  setSortOption: Dispatch<SetStateAction<string>>;
}

const Header: React.FC<IHeaderProps> = ({
  companies,
  selectedCompanies,
  setSelectedCompanies,
  sortOption,
  setSortOption,
}) => {
  return (
    <div className="flex flex-col md:flex-row justify-center w-full mt-4 xs:mt-5 sm:mt-6">
      <Filter
        companies={companies}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
      />
      <Sorter sortOption={sortOption} setSortOption={setSortOption} />
    </div>
  );
};

export default Header;
