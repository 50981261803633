import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

const Sorter = ({ sortOption, setSortOption }) => {
  const label = "Sort by";

  const options = [
    { name: "Company Name", value: "company_name" },
    { name: "Report Date", value: "report_date" },
    { name: "Featured", value: "featured" },
  ];

  return (
    <div className="w-full md:w-1/3 lg:w-1/4 xl:w-[19rem] mt-3 xs:mt-4 md:mt-0">
      <FormControl fullWidth variant="outlined">
        <InputLabel id={`select-${label}-label`}>{label}</InputLabel>
        <Select
          labelId={`select-${label}-label`}
          id={`select-${label}`}
          value={sortOption}
          onChange={(e) => {
            setSortOption(e.target.value);
          }}
          label={label}
          sx={{
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "0px",
            },
            ".MuiSelect-select": {
              backgroundColor: "white",
              "&:focus": {
                backgroundColor: "white",
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Sorter;
