import { useState, useEffect } from "react";

import { AxiosResponse } from "axios";
import { fetchDataFromCMS } from "../../cms-api";
import { useAuthContext } from "../../contexts/authContext";

const PAGE_SIZE = 100;

export function fetchCompanyNames() {
  const [companyNames, setCompanyNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<AxiosResponse<any, any> | null>(null);
  const { accessToken } = useAuthContext();

  const getCompanies = async (page: number) => {
    const params = {
      sort: ["name:asc"],
      fields: ["name"],
      pagination: {
        pageSize: PAGE_SIZE,
        page: page,
      },
    };

    try {
      const { data, error } = await fetchDataFromCMS(
        "/api/private-companies",
        accessToken,
        params,
        {
          method: "get",
        },
      );

      if (error) {
        throw error;
      }

      if (data) {
        setCompanyNames((prev) => [...prev, ...data.data]);
        if (page < data.meta.pagination.pageCount) {
          await getCompanies(page + 1);
        } else {
          setIsLoading(false);
        }
      }
    } catch (fetchError: any) {
      setError(fetchError);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (accessToken) {
      setIsLoading(true);
      setCompanyNames([]);
      setError(null);
      getCompanies(1);
    }
  }, [accessToken]);

  return {
    companyNames: companyNames.map((c) => c?.attributes?.name),
    isLoading,
    error,
  };
}
