import React, { useState } from "react";

const Tooltip: React.FC<{
  children: React.ReactNode;
  text: string;
  width: string;
}> = ({ children, text, width }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute mb-6 hidden flex-col items-center group-hover:flex top-[30px]">
        <span className="relative z-10 p-[4px] text-xs leading-none text-black whitespace-nowrap bg-white shadow-lg rounded border border-gray-300">
          {text}
          <div className="absolute w-2 h-2 left-[45%] bg-white transform rotate-45 -top-1 z-0 border-l border-t border-gray-300"></div>
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
