import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Autocomplete, TextField, Chip } from "@mui/material";

const Filter: React.FC<{
  companies: String[];
  selectedCompanies: String[];
  setSelectedCompanies: Dispatch<SetStateAction<never[]>>;
}> = ({ companies, selectedCompanies, setSelectedCompanies }) => {
  return (
    <div className="w-full md:w-2/3 lg:w-3/4 xl:w-[55rem] md:pr-3 lg:pr-4">
      <Autocomplete
        multiple
        id="autocomplete-privates"
        value={selectedCompanies}
        options={companies}
        onChange={(event, newValue) => setSelectedCompanies(newValue)}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => String(option)}
        renderOption={(props, option) => (
          <Box component="li" {...props} sx={{ display: "flex" }}>
            <Typography sx={{ color: "rgb(17,24,39)" }}>{option}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={"Search for a company"}
            sx={{
              ".MuiOutlinedInput-root": {
                borderRadius: "0px",
                bgcolor: "white",
              },
            }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip key={index} label={option} {...getTagProps({ index })} />
          ))
        }
        sx={{
          "& .MuiAutocomplete-tag": {
            borderRadius: "0px",
            margin: "2px",
          },
        }}
      />
    </div>
  );
};

export default Filter;
