import React from "react";
import { fetchCompanyNames } from "./api";
import { useDocuments } from "./hooks/useDocument";
import Header from "./Header";
import PageBoundary from "../PageBoundary";
import LoadingStatus from "../LoadingStatus";
import ReportCards from "./ReportCards";

const Privates: React.FC = () => {
  const { companyNames } = fetchCompanyNames();

  const {
    documents,
    isLoadingInitial,
    loadingError,
    selectedCompanies,
    setSelectedCompaniesWithReset,
    selectedSort,
    setSelectedSortWithReset,
    hasMore,
    isLoadingMore,
    loadMoreDocuments,
  } = useDocuments();

  const renderReports = () => {
    if (isLoadingInitial)
      return <LoadingStatus loadingType="reports_loading" />;
    if (loadingError) return <LoadingStatus loadingType="reports_error" />;
    if (documents.length == 0)
      return <LoadingStatus loadingType="reports_empty" />;

    const seen = {};
    const latestReportPerCompanyList = [];

    for (const doc of documents) {
      if (doc.attributes.company.data.id in seen) continue;
      latestReportPerCompanyList.push(doc);
      seen[doc.attributes.company.data.id] = true;
    }

    return (
      <ReportCards
        reports={latestReportPerCompanyList}
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  };

  return (
    <PageBoundary className="w-full">
      <Header
        companies={companyNames}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompaniesWithReset}
        sortOption={selectedSort}
        setSortOption={setSelectedSortWithReset}
      />
      {renderReports()}
    </PageBoundary>
  );
};

export default Privates;
